export const menuItemsADMIN = [{
        id: 1,
        label: "Compte",
        isTitle: true
    },
    {
        id: 2,
        label: "Tableau de bord",
        icon: "bx-home-circle",
        link: "/"
    },
    {
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 9,
        label: "Utilisateurs",
        icon: "bx-calendar",
        link: "/users"
    },
    {
        id: 10,
        label: "Moyens",
        icon: "bx-chat",
        link: "/moyens",
    },
    {
        id: 10,
        label: "Conversions",
        icon: "bx-chat",
        link: "/conversions",
    },
    {
        id: 10,
        label: "Transactions",
        icon: "bx-chat",
        link: "/transactions",
    },
    {
        id: 10,
        label: "Echanges",
        icon: "bx-chat",
        link: "/exchanges",
    },
    {
        id: 10,
        label: "Managers",
        icon: "bx-chat",
        link: "/managers",
    },
    {
        id: 11,
        label: "Divers",
        link: "#",
        icon: "bx-file",
        subItems: [{
            id: 13,
            label: "Constantes",
            link: "/constantes",
            parentId: 12
        }, ]
    },
    {
        id: 11,
        label: "Définition",
        link: "#",
        icon: "bx-file",
        subItems: [{
            id: 13,
            label: "Continents",
            link: "/continents",
            parentId: 12
        }, {
            id: 13,
            label: "Pays",
            link: "/countries",
            parentId: 12
        }, {
            id: 13,
            label: "Devises",
            link: "/currencies",
            parentId: 12
        }, ]
    },
];


export const menuItemsSector = [{
        id: 1,
        label: "Compte",
        isTitle: true
    },
    {
        id: 2,
        label: "Tableau de bord",
        icon: "bx-home-circle",
        link: "#"
    },
    {
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 11,
        label: "Projets",
        link: "#",
        icon: "bx-file",
        subItems: [{
            id: 13,
            label: "Tous les projets",
            link: "#",
            parentId: 12
        }, {
            id: 13,
            label: "Toutes les activités",
            link: "#",
            parentId: 12
        }, {
            id: 13,
            label: "Toutes les sous-activités",
            link: "#",
            parentId: 12
        }, ]
    },
    {
        id: 10,
        label: "Evènements",
        icon: "bx-chat",
        link: "#",
    },
    {
        id: 10,
        label: "Sondages",
        icon: "bx-chat",
        link: "#",
    },
    {
        id: 10,
        label: "Formulaires",
        icon: "bx-chat",
        link: "#",
    },
    {
        id: 10,
        label: "Discussions",
        icon: "bx-chat",
        link: "#",
    },
];

export const menuItemsPatner = [{
        id: 1,
        label: "Compte",
        isTitle: true
    },
    {
        id: 2,
        label: "Tableau de bord",
        icon: "bx-home-circle",
        link: "#"
    },
    {
        id: 7,
        label: "Menu",
        isTitle: true,
    },
    {
        id: 11,
        label: "Projets",
        link: "#",
        icon: "bx-file",
        subItems: [{
            id: 13,
            label: "Tous les projets",
            link: "#",
            parentId: 12
        }, {
            id: 13,
            label: "Toutes les activités",
            link: "#",
            parentId: 12
        }, {
            id: 13,
            label: "Toutes les sous-activités",
            link: "#",
            parentId: 12
        }, ]
    },
    {
        id: 10,
        label: "Discussions",
        icon: "bx-chat",
        link: "#",
    },
];

// export const menuItems = [{
//         id: 1,
//         label: "menuitems.menu.text",
//         isTitle: true
//     },
//     {
//         id: 2,
//         label: "menuitems.dashboards.text",
//         icon: "bx-home-circle",
//         badge: {
//             variant: "info",
//             text: "menuitems.dashboards.badge"
//         },
//         subItems: [{
//                 id: 3,
//                 label: "menuitems.dashboards.list.default",
//                 link: "/",
//                 parentId: 2
//             },
//             {
//                 id: 4,
//                 label: "menuitems.dashboards.list.saas",
//                 link: "#",
//                 parentId: 2
//             },
//             {
//                 id: 5,
//                 label: "menuitems.dashboards.list.crypto",
//                 link: "#",
//                 parentId: 2
//             },
//             {
//                 id: 6,
//                 label: "menuitems.dashboards.list.blog",
//                 link: "#",
//                 parentId: 2
//             }
//         ]
//     },
//     {
//         id: 7,
//         isLayout: true
//     },
//     {
//         id: 8,
//         label: "menuitems.apps.text",
//         isTitle: true
//     },
//     {
//         id: 9,
//         label: "menuitems.calendar.text",
//         icon: "bx-calendar",
//         link: "#"
//     },
//     {
//         id: 10,
//         label: "menuitems.chat.text",
//         icon: "bx-chat",
//         link: "#",
//     },
//     {
//         id: 11,
//         label: "menuitems.filemanager.text",
//         link: "#",
//         icon: "bx-file",
//         badge: {
//             variant: "success",
//             text: "menuitems.chat.badge"
//         }
//     },
//     {
//         id: 12,
//         label: "menuitems.ecommerce.text",
//         icon: "bx-store",
//         subItems: [{
//             id: 13,
//             label: "menuitems.ecommerce.list.products",
//             link: "#",
//             parentId: 12
//         }, ]
//     },
// ];